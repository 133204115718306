import React from 'react';
import { useDispatch } from 'react-redux';
import {
    Param,
    setNavigationParam,
    selectStateSearchAppLocation,
} from './searchSlice';
import { useAppSelector } from '../../app/hooks';

export function DropdownSearchOption(props: any) {
    const dispatch = useDispatch();
    const handleInputChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        e.preventDefault();
        const param: Param = { name: props.paramName, value: e.target.value }
        dispatch(setNavigationParam(param))
    }
    const stateSearchAppLocation = useAppSelector(selectStateSearchAppLocation);
    const [defaultValue, setDefaultValue] = React.useState(props.defaultValue);

    React.useEffect(() => {
        //check if value exists in navigation params
        if (stateSearchAppLocation && stateSearchAppLocation.queryParams && stateSearchAppLocation.queryParams[props.paramName]) {
            setDefaultValue(stateSearchAppLocation.queryParams[props.paramName]);
        }
        else {
            setDefaultValue(props.defaultValue);
        }

    }, [props.defaultValue, stateSearchAppLocation]);


    return (
        <React.Fragment>
            <div>
                <select onChange={handleInputChange} name="orderby" id="short" value={defaultValue}>
                    {props.options.map((opt: any) =>
                        <option key={opt.value} value={opt.value}  >{opt.label}</option>
                    )
                    }

                </select>
            </div>

        </React.Fragment>
    )
}
export default DropdownSearchOption