import React from "react";
import SwitchOption from "./SwitchOption";
import DropdownSearchOption from "./DropdownSearchOption";
import Slider from '@mui/material/Slider';
import { useDispatch } from 'react-redux';
import {
    Param,
    setNavigationParam,
    selectStateSearchAppLocation,
} from './searchSlice';
import { Box } from "@mui/material";
import ReactJson from 'react-json-view'
import { useAppSelector } from '../../app/hooks';

function SynonymOutputList(props: any) {
    const output = props.output;
    return (
        <React.Fragment>
            {
                output.map((out: string) =>
                    <div className="rewrite-output-value">{out}</div>
                )
            }
        </React.Fragment>
    )
}

function SynonymOutput(props: any) {
    const output = props.output;
    return (
        <React.Fragment>
            {
                (output != null && output.length > 0)
                    ? <div className="rewrite-output">
                        <SynonymOutputList output={output} />
                    </div>
                    : ""
            }
        </React.Fragment>
    )
}

function PhraseOutput(props: any) {
    const phraseRule = props.phrase_rule;
    const surfaceForm = phraseRule.surfaceForm;
    const rewriteType = phraseRule.rewriteType;
    return (
        <React.Fragment>
            {(rewriteType === "phrase")
                ?
                <div className="rewrite-output">
                    <div className="rewrite-output-value">
                        "{surfaceForm}"
                    </div>
                </div>
                : ""
            }
        </React.Fragment>
    )
}

function RewritesList(props: any) {
    const tagger = props.tagger;
    return (
        <React.Fragment>
            <div className="rules-rewrites-msg-wrapper">
                <div className="rewrites">
                    {
                        tagger.map((r: any) =>
                            <div className="rewrite">
                                <div className={'rewrite-type-' + r.rewriteType}>{r.rewriteType}</div>
                                <div className="rewrite-surface-form">{r.tag.surface_form}</div>
                                <div className="rewrite-action">{r.tag.action}</div>


                                <SynonymOutput output={r.tag.output} />
                                <PhraseOutput phrase_rule={r} />


                            </div>
                        )
                    }
                </div>
            </div>
        </React.Fragment>
    )
}
function Rewrites(props: any) {
    const tagger = props.tagger;
    return (
        <React.Fragment>
            {
                (tagger.length === 0)
                    ? ""
                    : <div className="col-md-6"><h3>Rewrites</h3><RewritesList tagger={tagger} /></div>
            }
        </React.Fragment>
    )
}

function RulesList(props: any) {
    const rules = props.rules;
    return (
        <React.Fragment>

            <div className="rules-rewrites-msg-wrapper">
                <div className="rewrites">{rules.length} rule(s) fired</div>
                <div className="rewrites">
                    {
                        rules.map((r: any) =>
                            <div className="rewrite">
                                {r.name}
                            </div>
                        )
                    }
                </div>
            </div>
        </React.Fragment>
    )
}

function Rules(props: any) {
    const rules = props.rules;
    return (
        <React.Fragment>
            {
                (rules.length === 0)
                    ? <div className="rules-rewrites-empty">No relevant rules</div>
                    : <div className="col-md-6"><h3>Rules</h3><RulesList rules={rules} /></div>
            }
        </React.Fragment>
    )
}


export function SliderParam(props: any) {
    const paramName = props.paramName;
    const dispatch = useDispatch();
    const stateSearchAppLocation = useAppSelector(selectStateSearchAppLocation);
    const defaultValue = props.defaultValue;

    const [value, setValue] = React.useState<number>(defaultValue);

    React.useEffect(() => {
      //check if value exists in navigation params
      if (stateSearchAppLocation && stateSearchAppLocation.queryParams && stateSearchAppLocation.queryParams[props.paramName]) {
        setValue(stateSearchAppLocation.queryParams[paramName]);
      }
      else {
        setValue(defaultValue);
      }
    }, [props.defaultValue, stateSearchAppLocation]);
  
    const handleChange = (event: Event, newValue: number | number[]) => {
        setValue(newValue as number);        
        const param: Param = { name: paramName, value: newValue }
        dispatch(setNavigationParam(param))
    };
    return (
        <Slider
            size="small"
            value={value}
            disabled={false}
            valueLabelDisplay="auto"
            step={0.5}
            min={0.0}
            onChange={handleChange}
            max={10.0}
            marks={[0,1,2,3,4,5,6,7,8,9,10].map((val) => {
                return { value: val, label: val.toString() }
            })}
            />

    )
}

export function DemoControlPanel(props: any) {
    const solrResponse = props.solrResponse;
    const tagger_rewrites = (solrResponse && solrResponse.fusion && solrResponse.fusion.tagger) ? solrResponse.fusion.tagger : [];
    const triggrered_rules = (solrResponse && solrResponse.fusion && solrResponse.fusion.applicable_rules) ? solrResponse.fusion.applicable_rules : [];
    const modelOptions = [
        { label: "E5 Large", value: "e5-large" },
        { label: "E5 Small", value: "e5-small" },
    ];

    const mmParameterOptions = [
        { label: "100%", value: "100%" },
        { label: "0", value: "0" },
        { label: "1", value: "1" },
        { label: "2", value: "2" },
    ]

    const responseHeaderParams = (solrResponse && solrResponse.responseHeader && solrResponse.responseHeader.params) ? solrResponse.responseHeader.params : {};
    const keysInterestedIn = ["q", "rq", "nhs_enabled", "nhs_model", "nhs_semantic_weight", "mm", "nhs_lexical_weight"];
    const filteredParams = Object.keys(responseHeaderParams)
        .filter(key => keysInterestedIn.includes(key))
        .reduce((obj: any, key) => {
            obj[key] = responseHeaderParams[key];
            return obj;
        }, {});

    const debugEnabled = (solrResponse && solrResponse.responseHeader && solrResponse.responseHeader.params && solrResponse.responseHeader.params.enableDebug === "true") ? true : false;
    return (
        <div className="democontrolpanel">



            <div className="form-check form-switch">
                <SwitchOption defaultValue={true} paramName="enableDebug" />
                <label className="form-check-label" >Debug</label>
                <div>
                </div>
            </div>

            <div className="form-check form-switch">
                <SwitchOption defaultValue={true} paramName="enableSignalBoosts" />
                <label className="form-check-label" >Boost with Signals</label>
                <div>
                </div>
            </div>



            <div className="form-check form-switch">
                <SwitchOption defaultValue={true} paramName="enableDynamicFacets" />
                <label className="form-check-label" >Dynamic Facets</label>
                <div>
                </div>
            </div>


            <hr />
            <h3>NHS</h3>

            <div className="">
                <label className="form-check-label" >Minimum Must Match (mm)</label>
                <DropdownSearchOption
                    options={mmParameterOptions}
                    defaultValue="100%"
                    paramName="mm"
                    label="Minimum Must Match (mm)"
                />
            </div>

            <div className="form-check form-switch mt-3">
                <SwitchOption defaultValue={true} paramName="nhs_enabled" />
                <label className="form-check-label" >Enable NHS</label>
                <div>
                </div>
            </div>
            {(solrResponse && solrResponse.responseHeader && solrResponse.responseHeader.params && solrResponse.responseHeader.params.nhs_enabled === "true")
                ? <>
                    <div className="">
                        <div>
                            <label className="form-check-label" >Embedding Model</label>
                        </div>
                        <DropdownSearchOption
                            options={modelOptions}
                            defaultValue="e5-large"
                            paramName="nhs_model"
                            label="NHS Model"
                        />

                    </div>

                    
                </>
                : <></>
            }

            {(debugEnabled)
                ? <> 
                
                <div style={{ maxWidth: '400px' }}>
                        <div className="mt-2">
                            <label className="form-check-label" >Semantic Weight</label>
                        </div>
                        <SliderParam paramName="nhs_semantic_weight"  defaultValue={5.0}/>
                    </div>

                    <div style={{ maxWidth: '400px' }}>
                        <div className="mt-2">
                            <label className="form-check-label" >Lexical Weight</label>
                        </div>
                        <SliderParam paramName="nhs_lexical_weight" defaultValue={5.0}/>
                    </div>
                    
                <hr />
                <h4>Params Debug</h4>
                <ReactJson src={filteredParams} collapsed={false} />
    </>
                :<></>
            }
           
        </div>


    )
}
export default DemoControlPanel