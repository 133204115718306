import React, { useState } from 'react';
import {
  setNavigationParam,
  selectStateSearchAppLocation,
  Param,

} from './searchSlice';
import { useAppDispatch } from '../../app/hooks';
import { useAppSelector } from '../../app/hooks';
import { stat } from 'fs';

export function SwitchOption(props: any) {
  const defaultValue = props.defaultValue;
  const paramName = props.paramName;

  const [toggle, setToggle] = useState(defaultValue);
  const dispatch = useAppDispatch();
  const stateSearchAppLocation = useAppSelector(selectStateSearchAppLocation);
  React.useEffect(() => {
    //check if value exists in navigation params
    if (stateSearchAppLocation && stateSearchAppLocation.queryParams && stateSearchAppLocation.queryParams[props.paramName]) {
      setToggle(stateSearchAppLocation.queryParams[props.paramName] === 'true');
    }
    else {
      setToggle(defaultValue);
    }
  }, [props.defaultValue, stateSearchAppLocation]);

  const handleInputChange = (event: any) => {
    const newValue = !toggle;
    setToggle(newValue);
    const param: Param = { name: paramName, value: String(newValue) }
    dispatch(setNavigationParam(param))
  };


  return (
    <React.Fragment>
      <input className="form-check-input" type="checkbox" checked={Boolean(toggle)} onChange={handleInputChange} />
    </React.Fragment>

  )
}
export default SwitchOption